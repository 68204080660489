import * as React from 'react';

import { graphql } from 'gatsby';
import queryString from 'query-string';

import CallOut from '../components/CallOut';
import Categories from '../components/Categories';
import Container from '../components/Container';
import Layout from '../components/Layout';

import { Baking } from '../icons';

const Message = {
  thank: 'Thank you for reaching out to us.',
  contact:
    'Thank you for reaching out to us. We hope to help you with your special day. Look out for an email or text message from us about the next steps in ordering from us.',
  subscribe:
    'Thank you for reaching out to us. You will now recieve email updates from Stella Sweets. We will not spam you or sell your email.',
};

const getMessage = message => (
  <p>
    {Message[message || 'thank']} In the meantime, check us out on{' '}
    <a
      href="https://www.facebook.com/StellaSweetsDesserts/"
      rel="noopener noreferrer"
      target="_blank"
    >
      Facebook
    </a>
    ,{' '}
    <a
      href="https://www.instagram.com/stellasweetsdesserts/"
      rel="noopener noreferrer"
      target="_blank"
    >
      Instagram
    </a>{' '}
    and{' '}
    <a
      href="https://www.yelp.com/biz/stellas-sweets-mission-viejo-2"
      rel="noopener noreferrer"
      target="_blank"
    >
      Yelp
    </a>
    .
  </p>
);

const ThankPage = ({ data, location }) => {
  const query = queryString.parse(location.search).m;
  return (
    <Layout pagetitle="Thank You" title="Sweet!">
      <Container>
        <CallOut icon={<Baking />}>{getMessage(query)}</CallOut>
      </Container>
      <Container>
        <Categories data={data.allContentfulProduct.edges} />
      </Container>
    </Layout>
  );
};

export default ThankPage;

export const pageQuery = graphql`
  query ThankQuery {
    allContentfulProduct(sort: { fields: type }) {
      edges {
        node {
          id
          type
          title
          menu
          categoryImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
